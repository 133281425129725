/*------------------------------------*\
    #SECTION COLLAPSE
\*------------------------------------*/

/*
Used for section sections that collapse on small screens, they
    can be toggled in or out of view.
    Typical use case is a section nav.

    NOTE:: Depends on section-collapse.scss

*/

'use strict';

WASABIWEB.sectionCollapse = function ($) {
    'use strict';
    $('.js-section-collapse').each(function () {
        var wrapper = $(this),
            toggle = wrapper.find('.js-section-collapse__toggle'),
            content = wrapper.find('.js-section-collapse__content');
        toggle.on('click', function (e) {
            e.preventDefault();
            toggle.toggleClass('section-collapse__toggle--open');
            content.toggleClass('section-collapse__content--open');
        });
    });
    return this;
};

// Paste into main.js: .sectionCollapse($)