/*------------------------------------*\
    #NAVBAR 
\*------------------------------------*/

'use strict';

WASABIWEB.navbar = function ($) {
    'use strict';
    WASABIWEB.calcNavbarCollapse('true');
    var toggleNav, touchFriendlySubNav, closeNavMobile, setNavMaxHeight;

    toggleNav = function (navbar) {
        navbar.on('click', '.js-toggle-nav', function (e) {
            if ($('body').hasClass('site-header-collapsed')) {
                e.preventDefault();
                $(this).closest('.js-nav-parent').toggleClass('open');
                if ($(this).closest('.js-site-header').length && !$(this).closest('.menu-item-has-children').length) {
                    $('.js-page-content, body').toggleClass('js-site-nav-open site-nav-open');
                    if ($('body').hasClass('js-site-nav-open')) {
                        $('body').css('padding-right', WASABIWEB.scrollbarWidth + 'px');
                        $('.js-navbar').css('right', WASABIWEB.scrollbarWidth + 'px');
                    } else {
                        $('body').css('padding-right', 0);
                        $('.js-navbar').css('right', 0);
                    }
                }
            }
        });
    };

    touchFriendlySubNav = function (navbar) {
        var delay = 1200,
            id,
            timer;
        navbar.find('.js-navbar__nav a').each(function (i) {
            $(this).attr('data-nav-id', i);
        });
        if (WASABIWEB.isTouch) {
            navbar.on('click', '.js-nav-parent >a', function (e) {
                var tempId;
                if (!navbar.closest('.navbar--collapsed').length) {
                    tempId = $(this).data('nav-id');
                    if (tempId !== id) {
                        e.preventDefault();
                        id = tempId;
                        timer = setTimeout(function () {
                            id = null;
                        }, delay);
                    } else {
                        clearTimeout(timer);
                        id = null;
                    }
                }
            });
        }
    };

    closeNavMobile = function (navbar) {
        // closes the mobile nav when user clicks on a link.
        if ($('body').hasClass('site-header-collapsed')) {
            navbar.on('click', 'a', function (e) {
                if (!$(e.target).closest('.js-toggle-nav').length) {
                    $('body').removeClass('js-site-nav-open site-nav-open');
                    $('.page-content').removeClass('js-site-nav-open site-nav-open');
                    $('.js-navbar').removeClass('open');
                }
            });
        }
    };

    setNavMaxHeight = function (navbar) {
        var siteHeaderTop = $('.js-site-header__top'),
            siteHeaderTopHeight = 0,
            siteNavParent = $('.js-site-header').find('.js-nav-parent'),
            siteNavParentHeight = 0;
        if (siteHeaderTop.length) {
            siteHeaderTopHeight = siteHeaderTop.outerHeight();
        }
        if (siteNavParent.length) {
            siteNavParentHeight = siteNavParent.outerHeight();
        }
        var winHeight = document.documentElement.clientHeight - (siteHeaderTopHeight + siteNavParentHeight);
        $('.js-navbar__nav').css({
            'max-height': winHeight + 'px'
        });
    };

    $('.js-navbar').each(function () {
        var el = $(this);
        //addRequiredClassesToSiteHeader(el);
        //calcBreakpoint(el, nav);
        toggleNav(el);
        setNavMaxHeight(el);
        touchFriendlySubNav(el);
        setTimeout(function () {
            closeNavMobile(el);
        }, 200);
    });

    $('#siteNav').find('a').each(function () {
        $(this).removeAttr('title');
    });

    return this;
};

// function highlightCurrentLocation() {
//           var siteNav = $('#siteNav'),
//               navPosition = siteNav.offset().top,
//               navHeight = siteNav.outerHeight(),
//               optionalOffset = 2;
//           siteNav.find('a').each(function() {
//               var el = $(this),
//                   elHref = el.attr('href'),
//                   elTarget = $(elHref);

//               if (elTarget.length) {
//                   var elTargetPosition = elTarget.offset().top,
//                   elTargetHeight = elTarget.outerHeight();
//                   if ((navPosition+navHeight+optionalOffset) >= elTargetPosition &&
//                       (navPosition+navHeight+optionalOffset) < (elTargetPosition+elTargetHeight)) {
//                       el.addClass('current-location');
//                   } else {
//                       el.removeClass('current-location');
//                   }
//               }
//           });
//       }