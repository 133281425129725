/*------------------------------------*\
    #STICKY 
\*------------------------------------*/

/*  makes an element sticky when the user scrolls past it's offset.

    when there are multiple objects that are sticky you may wish to add an 
    additional offset through the data-sticky-offset attribute.

    Requires a parent element in order to calculate the original offset once 
    the child element is sticky,This stops the page jumping up once the element 
    becomes affixed.

    <div class="js-sticky-parent">
        <div class="js-sticky" data-sticky-offset="0">
            Content
        </div>
    </div>

*/

'use strict';

WASABIWEB.sticky = function ($) {
    'use strict';
    var offsetTop,
        previousOffset = window.pageYOffset,
        // used to determine if
    //user has just scrolled.
    stickyElements = [],
        count,
        stickyElementsLength,
        currentEl,
        fixElements,
        WPnavbarHeight = 0,
        shrinkOffset = 200; // distance to scroll from fixed to shrink,
    // applies to navbar only.
    setTimeout(function () {
        // fixes issues with WP admin bar.
        WPnavbarHeight = $('#wpadminbar').outerHeight() || 0;
        if ($(window).width() < 600) {
            // WP admin bar is not position:fixed below this screen size.
            WPnavbarHeight = 0;
        }
    }, 500);

    fixElements = function (currentScroll) {
        for (count = 0; count < stickyElementsLength; count++) {
            currentEl = stickyElements[count];
            if (currentScroll > currentEl.positionTop - currentEl.dataOffset - WPnavbarHeight) {
                if (!currentEl.isFixed) {
                    currentEl.element.addClass('fixed').css('top', currentEl.dataOffset + WPnavbarHeight + 'px');
                    currentEl.isFixed = true;
                }
                if (currentScroll > currentEl.positionTop - (-shrinkOffset + currentEl.dataOffset) - WPnavbarHeight) {
                    currentEl.element.addClass('shrink');
                } else {
                    currentEl.element.removeClass('shrink');
                }
            } else {
                if (currentEl.isFixed) {
                    currentEl.element.removeClass('fixed').css('top', 'auto');
                    currentEl.isFixed = false;
                }
            }
        }
    };

    $('.js-sticky').each(function () {
        // cache all the stick elements into an array.
        var that = $(this);
        stickyElements.push({
            element: that,
            dataOffset: that.data('sticky-offset') || 0,
            parent: that.closest('.js-sticky-parent'),
            positionTop: that.parent().offset().top,
            isFixed: false
        });
    });
    stickyElementsLength = stickyElements.length;

    function loop() {
        offsetTop = window.pageYOffset;
        if (offsetTop == previousOffset) {
            requestAnimationFrame(loop);
            return false;
        } else {
            previousOffset = offsetTop;
        }
        fixElements(offsetTop);
        requestAnimationFrame(loop);
    }
    if (stickyElements.length) {
        fixElements(window.pageYOffset);

        // Call the loop for the first time
        requestAnimationFrame(loop);
    }
    return this;
};