/*------------------------------------*\
    #CHECK-CURRENT-MEDIA-QUERY 
\*------------------------------------*/

'use strict';

WASABIWEB.checkCurrentMediaQuery = {
    screenXs: false,
    screenSm: false,
    screenMd: false,
    screenLg: false,
    screenXl: false,
    init: function init($) {
        'use strict';
        var elWidth = $('#showBp').outerWidth();
        WASABIWEB.checkCurrentMediaQuery.screenXs = false;
        WASABIWEB.checkCurrentMediaQuery.screenSm = false;
        WASABIWEB.checkCurrentMediaQuery.screenMd = false;
        WASABIWEB.checkCurrentMediaQuery.screenLg = false;
        WASABIWEB.checkCurrentMediaQuery.screenXl = false;
        if (elWidth === 5) WASABIWEB.checkCurrentMediaQuery.screenXs = true;
        if (elWidth === 4) WASABIWEB.checkCurrentMediaQuery.screenSm = true;
        if (elWidth === 3) WASABIWEB.checkCurrentMediaQuery.screenMd = true;
        if (elWidth === 2) WASABIWEB.checkCurrentMediaQuery.screenLg = true;
        if (elWidth === 1) WASABIWEB.checkCurrentMediaQuery.screenXl = true;

        return WASABIWEB;
    }
};