
/*------------------------------------*\
    #IMG CONTROL
\*------------------------------------*/
'use strict';

(function ($) {
    $(function () {
        'use strict';
        WASABIWEB.imgControl = (function () {
            var options = {
                lazyOffset: 300,
                scrollFrameRate: 3
            };
            var globals = {
                scrollLooping: true,
                isSmallScreen: false,
                isMediumScreen: false,
                images: null,
                checkifSmallScreen: function checkifSmallScreen() {
                    if (WASABIWEB.checkCurrentMediaQuery.screenXs) {
                        return true;
                    }
                    return false;
                },
                checkifMediumScreen: function checkifMediumScreen() {
                    if (WASABIWEB.checkCurrentMediaQuery.screenSm) {
                        return true;
                    }
                    return false;
                },
                populateImagesArray: function populateImagesArray() {
                    var array = [];
                    $('.js-img-control').each(function () {
                        var that = $(this),
                            imgObj = that.data();
                        imgObj.el = that;
                        imgObj.hasLoadedLg = false;
                        imgObj.hasLoadedMd = false;
                        imgObj.hasLoadedSm = false;
                        imgObj.offsetTop = Math.floor(that.offset().top);
                        array.push(imgObj);
                    });
                    globals.images = array;
                },
                generateImagePlaceholders: function generateImagePlaceholders() {
                    var imagesArray = globals.images,
                        l = imagesArray.length,
                        i = 0,
                        width = 0,
                        height = 0,
                        paddingBottom = 0,
                        img = null;
                    for (i; i < l; i++) {
                        if (globals.isSmallScreen) {
                            width = imagesArray[i].smWidth || imagesArray[i].lgWidth;
                            height = imagesArray[i].smHeight || imagesArray[i].lgHeight;
                        } else if (globals.isMediumScreen) {
                            width = imagesArray[i].mdWidth || imagesArray[i].lgWidth;
                            height = imagesArray[i].mdHeight || imagesArray[i].lgHeight;
                        } else {
                            width = imagesArray[i].lgWidth;
                            height = imagesArray[i].lgHeight;
                        }
                        paddingBottom = height / width * 100;
                        img = imagesArray[i].el;
                        if (!imagesArray[i].spanContainer) {
                            if (img.data('fluid')) {
                                img.closest('.js-img-control-wrap').css({
                                    'max-width': width + 'px'
                                });
                            } else {
                                img.closest('.js-img-control-wrap').css({
                                    'width': width + 'px'
                                });
                            }
                        }
                        img.closest('.js-img-control-wrap__inner').css({
                            'padding-bottom': paddingBottom + '%'
                        });
                    }
                },

                isImageInViewport: function isImageInViewport(scrollOffset) {
                    var imageArray = globals.images,
                        l = imageArray.length,
                        i = 0,
                        winHeight = $(window).height(),
                        winBottomPosition = scrollOffset + winHeight;
                    for (i; i < l; i++) {
                        if (!imageArray[i].hasLoadedLg) {
                            // image has not yet loaded large img
                            if (!globals.isSmallScreen) {
                                // current screen is large
                                if (winBottomPosition + options.lazyOffset > imageArray[i].offsetTop) {
                                    globals.loadInImage(imageArray[i]);
                                    imageArray[i].hasLoadedLg = true;
                                    imageArray[i].hasLoadedMd = false;
                                    imageArray[i].hasLoadedSm = false;
                                }
                            }
                        }
                        if (!imageArray[i].hasLoadedMd) {
                            // Not yet loaded small image
                            if (globals.isMediumScreen) {
                                // current screen is small
                                if (winBottomPosition + options.lazyOffset > imageArray[i].offsetTop) {
                                    globals.loadInImage(imageArray[i]);
                                    imageArray[i].hasLoadedLg = false;
                                    imageArray[i].hasLoadedMd = true;
                                    imageArray[i].hasLoadedSm = false;
                                }
                            }
                        }
                        if (!imageArray[i].hasLoadedSm) {
                            // Not yet loaded small image
                            if (globals.isSmallScreen) {
                                // current screen is small
                                if (winBottomPosition + options.lazyOffset > imageArray[i].offsetTop) {
                                    globals.loadInImage(imageArray[i]);
                                    imageArray[i].hasLoadedLg = false;
                                    imageArray[i].hasLoadedMd = false;
                                    imageArray[i].hasLoadedSm = true;
                                }
                            }
                        }
                    }
                },

                loadInImage: function loadInImage(imgObj) {
                    var src = null,
                        el = imgObj.el;
                    if (globals.isSmallScreen) {
                        src = imgObj.smSrc;
                        if (!src) {
                            // no small src defined fallback to large src
                            src = imgObj.lgSrc;
                        }
                    } else if (globals.isMediumScreen) {
                        src = imgObj.mdSrc;
                        if (!src) {
                            // no small src defined fallback to large src
                            src = imgObj.lgSrc;
                        }
                    } else {
                        src = imgObj.lgSrc;
                    }
                    el.attr('src', src).load().css('opacity', 1);
                },
                detectScroll: function detectScroll() {
                    var isLooping = globals.scrollLooping,
                        offset = window.pageYOffset;

                    (function loop() {
                        offset = window.pageYOffset;
                        globals.isImageInViewport(offset);

                        if (isLooping) {
                            setTimeout(loop, 1000 / options.scrollFrameRate);
                        }
                    })();
                }
            };

            return {
                init: function initialise() {
                    var that = this;
                    // determine if small or medium screen or neither.
                    globals.isSmallScreen = globals.checkifSmallScreen();
                    globals.isMediumScreen = globals.checkifMediumScreen();

                    if (!globals.images) {
                        // images array not populated -> then populate
                        globals.populateImagesArray();
                        globals.detectScroll();
                    }

                    globals.generateImagePlaceholders();
                    that.getImageOffsets();

                    // Re-initialise on page resize -- if screen size has transitioned through Sass $screen-sm breakpoint.
                    var resizeScreen = function resizeScreen() {

                        setTimeout(function () {
                            that.getImageOffsets();
                            if (globals.checkifSmallScreen()) {
                                // is small screen
                                if (!globals.isSmallScreen) {
                                    //screen was previously not small
                                    globals.isSmallScreen = true;
                                    globals.isMediumScreen = false;
                                    initialise.call(that, {
                                        lazyOffset: options.lazyOffset,
                                        scrollFrameRate: options.scrollFrameRate
                                    });
                                }
                            } else if (globals.checkifMediumScreen()) {
                                // is medium screen
                                if (!globals.isMediumScreen) {
                                    //screen was previously not medium
                                    globals.isSmallScreen = false;
                                    globals.isMediumScreen = true;
                                    initialise.call(that, {
                                        lazyOffset: options.lazyOffset,
                                        scrollFrameRate: options.scrollFrameRate
                                    });
                                }
                            } else {
                                // is large screen
                                if (globals.isSmallScreen || globals.isMediumScreen) {
                                    // screen was previously not large
                                    globals.isSmallScreen = false;
                                    globals.isMediumScreen = false;
                                    initialise.call(that, {
                                        lazyOffset: options.lazyOffset,
                                        scrollFrameRate: options.scrollFrameRate
                                    });
                                }
                            }
                        }, 200);
                    };
                    var efficientFn = WASABIWEB.debounce(resizeScreen, 300);
                    $(window).on('resize', efficientFn);
                    $(window).on('orientationchange', resizeScreen);

                    return WASABIWEB;
                },
                getImageOffsets: function getImageOffsets() {
                    var imageArray = globals.images,
                        imageOffset = 0,
                        i,
                        l = imageArray.length;

                    for (i = 0; i < l; i++) {
                        // get image offset
                        imageOffset = Math.floor(imageArray[i].el.offset().top);
                        // and add it to the image object.
                        imageArray[i].offsetTop = imageOffset;
                    }
                }
            };
        })();
    });
})(jQuery);
/*
      
    This module both lazy loads images and changes the src 
    depending on the viewport size.



    Examples:


    Standard lazy load image

    <div class="img-control-wrap js-img-control-wrap">
        <div class="img-control-wrap__inner js-img-control-wrap__inner">
            <img src="<?php bloginfo('template_directory'); ?>/img/build/media/blank.gif"
                class="js-img-control"
                data-lg-src="<?php bloginfo('template_directory'); ?>/img/build/media/600x400.jpg"
                data-lg-width="600"
                data-lg-height="400"
                data-fluid="true"
                alt="">
        </div>
    </div>


    Lazy load image that spans full container width, even if container is larger than img.

    <div class="img-control-wrap js-img-control-wrap">
        <div class="img-control-wrap__inner js-img-control-wrap__inner">
            <img src="<?php bloginfo('template_directory'); ?>/img/build/media/blank.gif"
                class="js-img-control"
                data-lg-src="http://lorempixel.com/400/200/sports"
                data-lg-width="400"
                data-lg-height="200"
                data-span-container="true"
                data-fluid="true"
                alt="">
        </div>
    </div>


    Lazy load image that also has smaller version for sm screens

    <div class="img-control-wrap js-img-control-wrap">
        <div class="img-control-wrap__inner js-img-control-wrap__inner">
            <img src="<?php bloginfo('template_directory'); ?>/img/build/media/blank.gif"
                class="js-img-control"
                data-lg-src="http://lorempixel.com/400/600/sports"
                data-lg-width="400"
                data-lg-height="600"
                data-sm-src="http://lorempixel.com/100/100/sports"
                data-sm-width="100"
                data-sm-height="100"
                data-fluid="true"
                alt="">
        </div>
    </div>

    Lazy load image that also has smaller version for md screens

    <div class="img-control-wrap js-img-control-wrap">
        <div class="img-control-wrap__inner js-img-control-wrap__inner">
            <img src="<?php bloginfo('template_directory'); ?>/img/build/media/blank.gif"
                class="js-img-control"
                data-lg-src="http://lorempixel.com/400/600/sports"
                data-lg-width="400"
                data-lg-height="600"
                data-md-src="http://lorempixel.com/100/100/sports"
                data-md-width="100"
                data-md-height="100"
                data-fluid="true"
                alt="">
        </div>
    </div>

*/