/*------------------------------------*\
    #SCREEN-RESIZE-EVENTS 
\*------------------------------------*/

'use strict';

WASABIWEB.screenResizeEvents = function ($) {
    'use strict';
    var args = arguments,
        efficientFn;
    efficientFn = WASABIWEB.debounce(function () {
        window.requestAnimationFrame(function () {
            var i,
                l = args.length;
            for (i = 1; i < l; i++) {
                // start loop at 1 to avoid calling jQuery
                args[i]($);
            }
        });
    }, 200);
    $(window).on('resize', efficientFn);
};