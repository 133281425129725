/*------------------------------------*\
    #SCROLL-EVENTS 
\*------------------------------------*/

'use strict';

WASABIWEB.scrollEvents = function ($) {
    'use strict';
    var currentOffset,
        previousOffset = window.pageYOffset,
        args = arguments;
    function loop() {
        var i,
            l = args.length;
        currentOffset = window.pageYOffset;
        if (currentOffset == previousOffset) {
            requestAnimationFrame(loop);
            return false;
        } else {
            previousOffset = currentOffset;
        }
        for (i = 1; i < l; i++) {
            // start loop at 1 to avoid calling jQuery
            args[i]($);
        }

        requestAnimationFrame(loop);
    }
    // Call the loop for the first time
    requestAnimationFrame(loop);

    return this;
};