/*------------------------------------*\
    #MAIN
\*------------------------------------*/

"use strict";

(function ($) {
    $(function () {

        /* PUT ANY AJAX STUFF INSIDE THE FUNCTION BELOW IT'S IMPORTANT THESE GET ATTACHED TO THE WASABIWEB OBJECT SO
            WE DON'T POLLUTE THE GLOBAL NAMESPACE. */

        WASABIWEB.processAjax = function ($) {
            /* Ajax stuff
            $.ajax() ....
            */
            return this;
        };
        WASABIWEB.plugins($)
        // module calls
        .checkCurrentMediaQuery.init($).navbar($).peppermintSlider($).sticky($).imgControl.init($).processAjax($).sectionCollapse($).photoswipe($).scrollEvents($
        // window scroll event calls
        // pass in function names as arguments.
        // e.g :
        //WASABIWEB.navbar,
        //WASABIWEB.peppermint
        ).screenResizeEvents($, WASABIWEB.checkCurrentMediaQuery.init
        // pass in function names as arguments.
        // e.g :
        //WASABIWEB.navbar,
        //WASABIWEB.peppermint
        );
    });
})(jQuery);